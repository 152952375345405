import ky, { Options } from "ky";

const apiOptions: Options = {
  prefixUrl: "https://ds.napps.navo-it.dk/api",
  searchParams: {
    key: "8ed9a403-2b29-4f4c-90aa-97617ace9407",
    backend: "nc",
    site: LanguageID,
  },
  retry: 4,
  // fetch: window.kyFetch,
};

export const shippingApi = ky.create(apiOptions);
